import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Hotel Restoration Services | Hotel Cleaning | HCS"
        description="Need five-star hotel restoration services nationwide? We can reverse what wear & tear have done to your flooring & surfaces. Get in touch today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">Hotel Restoration</h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            We make your floors and countertops luxurious once again.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Services"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="What We Specialize In"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>Revitalize & Refresh</h2>
                  <p>
                    Your hotel’s most highly trafficked and touched areas are
                    your floors and countertops. Over time they can certainly
                    lose their elegant appearance. Our restoration specialists
                    can help return the natural beauty of wood, stone, and
                    marble to keep your floors and countertops as lovely as the
                    day they were installed.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    Our Restoration Services
                  </h2>
                  <p>
                    At Hotel Cleaning Services, we can reverse what normal wear
                    and tear have done to your flooring and surfaces. Our
                    restoration experts specialize in the following:
                  </p>
                  <ul>
                    <li>Wood Floors</li>
                    <li>Stone Floors</li>
                    <li>Marble Floors</li>
                    <li>Stone Countertops</li>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    More On What We Specialize In
                  </h2>

                  <h3>Wood Floor Restoration</h3>
                  <p>
                    Wood flooring instantly sets the mood in an environment.
                    Lighter wood brings a fresh, spacious feeling, and rich,
                    darker wood is warm and inviting.
                  </p>

                  <p>
                    They’re lovely, no matter their tone, but can wear over
                    time, especially when there’s a high volume of foot traffic.
                    The flooring can fade, stains may set in, or there may be
                    damage, such as gouges or warping.
                  </p>

                  <p>
                    Our team takes expert care when restoring wood flooring,
                    whether it’s a complete refinish to bring it back to its
                    original beauty or the maintenance in between to keep it
                    looking its best.
                  </p>

                  <h3>Stone & Marble Flooring Restoration</h3>
                  <p>
                    First impressions are essential in hospitality, and when it
                    comes to stone and marble floors, you want them to stand
                    out. We’ll make them look their very best to ensure a
                    positive guest experience.
                  </p>
                  <p>
                    From travertine and granite to stained concrete and Italian
                    marble, we can restore it all. We specialize in sealing and
                    polishing, pasting and crystallization, all the way up to a
                    complete diamond hone marble restoration.
                  </p>
                  <p>
                    We also offer a regular maintenance program customized for
                    your property to keep your restored flooring looking its
                    best every day.
                  </p>

                  <h3>Stone Countertop Restoration</h3>
                  <p>
                    Some of the most abused areas in your hotel are stone
                    countertops located in your food and beverage outlets and
                    restrooms. These surfaces are bombarded daily with water,
                    spilled beverages, soap residue, and more.
                  </p>
                  <p>
                    We have the expertise to restore your countertops to their
                    original luster and beauty. Our comprehensive restoration
                    process includes diamond honing and polishing. We use
                    diamond-embedded sanding discs and a specially formulated
                    polishing paste to bring back the stone’s character and
                    appearance.
                  </p>
                  <p>Our expert staff can handle any job.</p>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={[
          "Contact the ",
          <span className="text-primary-500">Experts</span>,
        ]}
        subtext="We have high-skilled restoration specialists who are ready to exceed your expectations. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Restoration Services.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Restoration Services.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: {
        eq: "services/2.5 Restoration Services/1.0 Restoration Hero Desktop.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: {
        eq: "services/2.5 Restoration Services/1.0 Restoration Hero mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
